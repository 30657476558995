.expContainer {
    color: white;
    padding: 20px 0;
}

.experienceItemContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 20px 0;
}

.experienceItem {
    color: white;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
}

.topSection {
    font-size: 20px;
    display: grid;
    grid-template-columns: 1fr 0.3fr;
    align-items: center;
    row-gap: 30px;
}

.companyLogo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 130px;
    width: 130px;
    border-radius: 50%;
    background-color: #ffffff0a;
}

@media only screen and (max-width: 600px) {
    .experienceItemContainer {
        grid-template-columns:  1fr;
    }
}