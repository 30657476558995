@import url('https://fonts.googleapis.com/css?family=Audiowide|Sofia|Trirong|Fredoka');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css2?family=Berkshire+Swash&family=Raleway:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Allura&family=Beau+Rivage&family=Berkshire+Swash&family=Great+Vibes&family=Pacifico&family=Raleway:wght@500&family=Tangerine:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Overlock&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Overlock&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Raleway', cursive;
}

:root {
    --dark-navy: #020c1b;
    --navy: #0a192f;
    --light-navy: #112240;
    --lightest-navy: #233554;
    --navy-shadow: rgba(2, 12, 27, 0.7);
    --dark-slate: #495670;
    --slate: #8892b0;
    --light-slate: #a8b2d1;
    --lightest-slate: #ccd6f6;
    --white: #e6f1ff;
    --green: #64ffda;
    --green-tint: rgba(100, 255, 218, 0.1);
    --pink: #f57dff;
    --blue: #57cbff;
    --font-sans: 'Calibre', 'Inter', 'San Francisco', 'SF Pro Text',
    -apple-system, system-ui, sans-serif;
    --font-mono: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', monospace;
}

body::-webkit-scrollbar {
    display: none;
}

/* body::-webkit-scrollbar-track {

		background-color: var(--light-navy);
}

body::-webkit-scrollbar-thumb {
  background-color: whitesmoke;
	opacity: 0.5;
	border-radius: 10px;
} */

@tailwind base;
@tailwind components;
@tailwind utilities;