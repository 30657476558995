* {
    box-sizing: border-box;
}

.appContainer {
    background-color: var(--light-navy);
    padding: 0px !important;
}

.headerContainer {
    background-color: var(--light-navy);
    /* background-color: #6600FF; */
    padding: 5px;
    text-align: center;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
    z-index: 100;
    width: 100%;
}

.aboutSec {
    padding: 30px 0px;
    color: white;
    font-size: 20px;
    text-align: justify;
}

.abtList {
    columns: 2;
    font-size: 18px;
}

abtList li {
    list-style-type: circle;
}


.compDesContainer {
    padding-top: 10px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 0px 0px 10px 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);

    border: 1px solid rgba(255, 255, 255, 0.05);
}

.compDesContainer ul li {
    padding: 10px 0px;
}

.hidden {
    display: none;
}

.footerCont {
    width: 100%;
    padding: 10px;
    color: white;
    text-align: center;
    background-color: var(--navy);
}

.projCont {
    color: white;
}

.projContHead {
    color: white;
    text-align: center;
}

.indProj {
    margin: 20px 5px !important;
    background-color: var(--navy);
    border-radius: 10px;
}

.ProjectImage {
    width: 100%;
}

.ProjectImage:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 1);
}

.indProjDes {
    background-color: var(--navy);
    text-align: justify;
}

.projBtn {
    width: 100%;
}

/* For HR line to Sec header */
.separator {
    display: flex;
    align-items: center;
    text-align: center;
}

.separator::before,
.separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid var(--green);
}

.separator:not(:empty)::before {
    margin-right: 1em;
}

.separator:not(:empty)::after {
    margin-left: 1em;
}

.scrollContainer {
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
}

.scrollItem {
    scroll-snap-align: start;
}

.scrollContainer::-webkit-scrollbar {
    display: none;
}

@media only screen and (max-width: 600px) {
    .aboutEmail {
        overflow: scroll;
    }

    .expHeader,
    .expData {
        padding: 10px 5px;
    }

    .medQuRevCol {
        flex-direction: column-reverse;
    }

    .scrollContainer {
        scroll-snap-type: y approximate;
    }
}
